@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/source-sans-pro/SourceSansPro-Regular.otf")
    format("opentype");
}

@font-face {
  font-family: "Source Sans Pro SemiBold";
  src: url("./assets/fonts/source-sans-pro/SourceSansPro-Semibold.otf")
    format("opentype");
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter/static/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Red Hat Display";
  src: url("./assets/fonts/Red_Hat_Display/static/RedHatDisplay-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Source Sans three";
  src: url("./assets/fonts/Source_Sans_3/static/SourceSans3-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("./assets/fonts/Plus_Jakarta_Sans/static/PlusJakartaSans-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Plus Jakarta Sans Bold";
  src: url("./assets/fonts/Plus_Jakarta_Sans/static/PlusJakartaSans-Bold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Plus Jakarta Sans SemiBold";
  src: url("./assets/fonts/Plus_Jakarta_Sans/static/PlusJakartaSans-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins Bold";
  src: url("./assets/fonts/Poppins/Poppins-Bold.ttf");
}
